import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useLanguageContext } from '../Context/LanguageContext'
import { NAVIGATION, customTheme } from '../Constants/constants'
import { useNavigate } from 'react-router-dom'

const AppBarText = ({ content, onClick, flexGrow, cursor }) => (
  <Typography
    onClick={onClick}
    color={customTheme.textColor}
    flexGrow={flexGrow}
    sx={{
      mx: 0.5,
      fontSize: 'inherit',
      cursor: cursor,
      display: 'flex',
      alignItems: 'center', // Ensure vertical alignment
      height: '100%', // Take full height of the parent container
      '@media (max-width: 700px)': {
        fontSize: customTheme.textSizeSmall,
      },
    }}>
    {content}
  </Typography>
)

export const AppBarGrey = () => {
  const { changeLanguage, languageButtonText, TEXT } = useLanguageContext()
  const navigate = useNavigate()

  const handleNavigateToContact = () => {
    navigate(NAVIGATION.contact)
  }

  const handleNavigateToFrontPage = () => {
    navigate(NAVIGATION.frontpage)
  }

  const CONTENT = [
    { content: TEXT.kristian, onClick: null, flexGrow: 1, cursor: '' },

    {
      content: TEXT.frontpage,
      onClick: handleNavigateToFrontPage,
      flexGrow: 0,
      cursor: 'pointer',
    },
    {
      content: TEXT.contact,
      onClick: handleNavigateToContact,
      flexGrow: 0,
      cursor: 'pointer',
    },

    {
      content: languageButtonText,
      onClick: changeLanguage,
      flexGrow: 0,
      cursor: 'pointer',
    },
  ]

  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          mb: 3,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: customTheme.backgroundColor,
          '@media (max-width: 700px)': {
            height: '40px', // Adjust this value as needed
          },
        }}>
        <Toolbar
          sx={{
            flexWrap: 'wrap',
            '@media (max-width: 700px)': {
              minHeight: '40px', // Adjust as needed
            },
          }}>
          {CONTENT.map((item, index) => (
            <AppBarText
              key={index}
              content={item.content}
              onClick={item.onClick}
              flexGrow={item.flexGrow}
              cursor={item.cursor}
            />
          ))}
        </Toolbar>
      </AppBar>
    </>
  )
}
