export const NAVIGATION = {
  frontpage: '/',
  lego: '/lego',
  contact: 'contact',
}

export const customTheme = {
  backgroundColor: (theme) => theme.palette.grey[200],
  headerColor: 'text.primary',
  textColor: 'text.secondary',
  textSizeSmall: '0.8rem',
}
