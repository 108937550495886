import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import { LanguageContextProvider } from './Context/LanguageContext'
import { ModalContextProvider } from './Context/ModalContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ModalContextProvider>
      <LanguageContextProvider>
        <App />
      </LanguageContextProvider>
    </ModalContextProvider>
  </React.StrictMode>
)
