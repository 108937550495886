import { createContext, useContext, useState } from 'react'

const ModalContext = createContext()

export const ModalContextProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleOpen = () => {
    setModalOpen(true)
    navigator.clipboard.writeText('kkm@mensa.dk')
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  return (
    <ModalContext.Provider
      value={{
        modalOpen,
        handleClose,
        handleOpen,
      }}>
      {children}
    </ModalContext.Provider>
  )
}

export const useModalContext = () => useContext(ModalContext)
