import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { customTheme } from '../Constants/constants'

export const PageComponent = ({ title, subtitle, videoUrl }) => {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'background.paper',
          pt: 3,
          pb: 3,
          '@media (max-width: 700px)': {
            pt: 0,
            pb: 0.5,
          },
        }}>
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color={customTheme.headerColor}
            gutterBottom
            sx={{
              '@media (max-width: 700px)': {
                fontSize: '1.5rem',
              },
            }}>
            {title}
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color={customTheme.textColor}
            paragraph
            sx={{
              '@media (max-width: 700px)': {
                fontSize: customTheme.textSizeSmall,
              },
            }}>
            {subtitle}
          </Typography>
        </Container>
      </Box>
      {videoUrl && (
        <Container
          maxWidth="md"
          sx={{ mb: 8 }}>
          <Grid
            container
            sx={{ height: '100%' }}>
            <Grid
              item
              xs={12}
              sx={{ height: '100%', position: 'relative' }}>
              <Box
                sx={{
                  width: '100%',
                  position: 'relative',
                  paddingTop: '56.25%',
                }}>
                <iframe
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                  width="560"
                  height="350"
                  src={`https://www.youtube.com/embed/${videoUrl}?rel=0&modestbranding=1`}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  )
}
