import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { customTheme } from '../Constants/constants'
import { useModalContext } from '../Context/ModalContext'
import { useLanguageContext } from '../Context/LanguageContext'

export const Footer = () => {
  const { handleOpen } = useModalContext()
  const { TEXT } = useLanguageContext()

  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        backgroundColor: customTheme.backgroundColor,
      }}>
      <Container maxWidth="sm">
        <Typography
          onClick={handleOpen}
          variant="body2"
          color={customTheme.headerColor}
          textAlign="center"
          sx={{ flexGrow: 1, cursor: 'pointer' }}>
          {TEXT.email}
        </Typography>
      </Container>
    </Box>
  )
}
