import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { customTheme } from '../Constants/constants'
import { useLanguageContext } from '../Context/LanguageContext'

export const Contact = () => {
  const { TEXT } = useLanguageContext()

  const contactInfo = [
    { title: TEXT.titleName, content: 'Kristian Kassø Meier', link: 'N/A' },
    { title: TEXT.titleTelephone, content: '+45 51 92 58 89', link: 'N/A' },
    {
      title: TEXT.titleEmail,
      content: 'kkm@mensa.dk',
      link: 'mailto:kkm@mensa.dk',
    },
    {
      title: TEXT.titleWork,
      content: 'Danmarks Statistik',
      link: 'https://www.dst.dk/da/OmDS/organisation/Telefonbog?q=meier',
    },

    {
      title: TEXT.titleProjects,
      content: TEXT.contentProjects,
      link: 'https://kristian-projects.com/',
    },
    {
      title: TEXT.titleYoutube,
      content: '@KristianCodes',
      link: 'https://www.youtube.com/@KristianCodes',
    },
    {
      title: TEXT.titleInstagram,
      content: 'kristiankmeier',
      link: 'https://www.instagram.com/kristiankmeier/',
    },
    {
      title: TEXT.titleLinkedIn,
      content: 'kristiankmeier',
      link: 'https://www.linkedin.com/in/kristiankmeier',
    },
  ]

  const renderContent = (content, link) => {
    if (link !== 'N/A') {
      return (
        <Link
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ textDecoration: 'none', color: customTheme.linkColor }}>
          {content}
        </Link>
      )
    }
    return content
  }

  return (
    <Box sx={{ bgcolor: 'background.paper', pt: 2, pb: 6 }}>
      <Container maxWidth="md">
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color={customTheme.headerColor}
          gutterBottom
          sx={{ pb: 2 }}>
          {TEXT.contactHeadline}
        </Typography>
        <TableContainer
          component={Paper}
          sx={{
            width: 300,
            margin: 'auto', // This centers the table within the container
          }}>
          <Table aria-label="contact information table">
            <TableBody>
              {contactInfo.map((info) => (
                <TableRow key={info.title}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      width: 120,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}>
                    {info.title}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      width: 180,
                    }}>
                    {renderContent(info.content, info.link)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  )
}
