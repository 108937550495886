import { useLanguageContext } from '../Context/LanguageContext'
import { PageComponent } from '../Components/PageComponent'

export const Frontpage = () => {
  const { TEXT } = useLanguageContext()

  return (
    <PageComponent
      title={TEXT.video_heading}
      subtitle={TEXT.video_subtitle}
      videoUrl="zp_nhNXz6S4"
    />
  )
}
