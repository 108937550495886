import { Layout } from './Components/Layouts'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { NAVIGATION } from './Constants/constants'
import { Frontpage } from './Pages/FrontPage'
import { Contact } from './Pages/Contact'

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route
            path={NAVIGATION.frontpage}
            element={<Frontpage />}
          />
          <Route
            path={NAVIGATION.contact}
            element={<Contact/>}
          />
          <Route
            path="*"
            element={
              <Navigate
                to="/"
                replace
              />
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
